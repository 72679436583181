.updates-list {
    display: flex;
    flex-direction: column;

    padding: 1rem;

    gap: 5rem;

    .updates-item {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    @media screen and (min-width: 768px) {
        .updates-item {
            display: grid;
            // grid-template-columns: 1fr 2fr;
        }
    }
}
